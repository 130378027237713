import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";

import usersReducers from "./users/";
import dashboardReducer from "./dashboard/dashboardReducer";
import { giftRewardsReducer } from "./giftRewards/giftRewardsReducer";
import { otherGiftRewardsReducer } from "./otherRewards/otherRewardsReducer";
import { stakingPageListReducer } from "./stakingPage/stakingPageReducer";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  users: usersReducers,
  dashboard: dashboardReducer,
  giftRewards: giftRewardsReducer,
  otherRewards: otherGiftRewardsReducer,
  stakingPage: stakingPageListReducer,
});

export default rootReducer;
